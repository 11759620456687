@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.makeStyles-searchArea-22 {
  margin: 20px 0px !important;
}

.jss22{
  margin-top: 20px !important;
}


@media only screen and (max-width: 964px) {
  .test {
    display: none !important;
  }

  .makeStyles-searchArea-22 {
    margin: 0px 0px !important;
  }

  .makeStyles-name-364 {
    display: none !important;
  }

  .welcome-user-text{
    display: none ;
  }

  .home-main-text{
    font-size: 24px !important;
  }

  .home-secondary-text{
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 768px) {
  .home-main-text{
    font-size: 22px !important;
  }

  .home-main-text{
    font-size: 24px !important;
  }

  .home-secondary-text{
    display: none !important;
  }

}


@media only screen and (max-width: 400px) {
  .home-secondary-text{
    display: none;
  }

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  font-family: 'Roboto';
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

