body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.makeStyles-searchArea-22 {
  margin: 20px 0px !important;
}

.jss22{
  margin-top: 20px !important;
}


@media only screen and (max-width: 964px) {
  .test {
    display: none !important;
  }

  .makeStyles-searchArea-22 {
    margin: 0px 0px !important;
  }

  .makeStyles-name-364 {
    display: none !important;
  }

  .welcome-user-text{
    display: none ;
  }

  .home-main-text{
    font-size: 24px !important;
  }

  .home-secondary-text{
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 768px) {
  .home-main-text{
    font-size: 22px !important;
  }

  .home-main-text{
    font-size: 24px !important;
  }

  .home-secondary-text{
    display: none !important;
  }

}


@media only screen and (max-width: 400px) {
  .home-secondary-text{
    display: none;
  }

}